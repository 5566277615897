import ScrollableInstance, { BaseAddon } from '@/components/UI/ScrollableArea/ScrollableInstance'

export default class implements BaseAddon {
  delay: number
  timeoutId: number | null

  onStart: (() => unknown) | null
  onEnd: (() => unknown) | null

  constructor (delay: number) {
    this.delay = delay
    this.timeoutId = null

    this.onStart = null
    this.onEnd = null
  }

  activate = (instance: ScrollableInstance) => {
    this.onStart = () => {
      const { thumbElement } = instance
      thumbElement && (thumbElement.style.opacity = '1')
    }

    this.onEnd = () => {
      const { thumbElement } = instance
      thumbElement && (thumbElement.style.opacity = '0')
      this.timeoutId = null
    }

    this.onEnd()
  }

  onScroll = () => {
    if (!this.onEnd) return

    const stopped = this.stopTimeout()

    if (!stopped) {
      this.onStart && this.onStart()
    }

    this.timeoutId = setTimeout(this.onEnd, this.delay)
  }

  flush = () => {
    this.stopTimeout()
  }

  private stopTimeout = () => {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId)
      this.timeoutId = null

      return true
    }
    return false
  }
}
