import DOMUtils, { flushElement } from '@/utils/DOM'
// import { defaultLogger } from '@/loggers'

const AREA_CLASS_NAME = 'scrollable-area'
const CONTENT_CLASS_NAME = 'scrollable-content'
const TRACK_CLASS_NAME = 'track'
const THUMB_CLASS_NAME = 'thumb'

const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') >= 0
export let firefoxInnerMargin: number | null = null

export type UpdateCallback = (scrollTop: number, scrollHeight: number, clientHeight: number, manually: boolean) => void
// eslint-disable-next-line no-use-before-define
export type OnScrollCallback = (instance: ScrollableInstance) => void

export interface BaseAddon {
  flush: () => void;
  // eslint-disable-next-line no-use-before-define
  activate?: (instance: ScrollableInstance) => void;
  onScroll?: OnScrollCallback;
  onUpdate?: UpdateCallback;
}

export default class ScrollableInstance {
  parentContainerElement: HTMLElement
  areaElement: HTMLElement
  contentElement: HTMLElement
  thumbElement: HTMLElement
  trackElement: HTMLElement

  scrollRelatedAddons: Array<BaseAddon> | null
  updateRelatedAddons: Array<BaseAddon> | null

  appearance: string
  embedded?: boolean

  updateCallback?: UpdateCallback
  observer: MutationObserver | null = null

  isNextScrollUpdateProgrammatically = false
  previousPageY = 0
  preIsVisible?: boolean
  cachedScrollTop = 0
  cachedScrollHeight = 0
  cachedClientHeight = 0

  activated = false

  constructor ({ container, embedded, appearance = 'default', updateCallback, addons }: { container: HTMLElement; embedded?: boolean; appearance?: string; updateCallback?: UpdateCallback; addons?: Array<BaseAddon> }) {
    this.appearance = appearance
    this.embedded = embedded
    this.updateCallback = updateCallback

    if (container.classList.contains(AREA_CLASS_NAME)) {
      this.areaElement = container
      this.parentContainerElement = this.areaElement.parentNode as HTMLElement
    } else {
      this.areaElement = this.createScrollableAreaElement()
      this.parentContainerElement = container
      this.parentContainerElement.appendChild(this.areaElement)
    }

    this.scrollRelatedAddons = this.updateRelatedAddons = null

    this.contentElement = this.areaElement.lastChild as HTMLElement
    if (!this.contentElement || this.contentElement.className !== CONTENT_CLASS_NAME) {
      throw new Error('[ScrollableWrapperInstance.setupElements] Content element is invalid')
    }

    if (isFirefox) {
      firefoxInnerMargin === null
        ? this.calculateFirefoxScrollMargin()
        : this.fixFirefoxScrollMargin()
    }

    this.trackElement = this.areaElement.firstChild as HTMLElement
    if (!this.trackElement || this.trackElement.className !== TRACK_CLASS_NAME) {
      throw new Error('[ScrollableWrapperInstance.setupElements] Track element is invalid')
    }

    this.thumbElement = this.trackElement.firstChild as HTMLElement
    if (!this.thumbElement || this.thumbElement.className !== THUMB_CLASS_NAME) {
      throw new Error('[ScrollableWrapperInstance.setupElements] Thumb element is invalid')
    }

    this.attachEvents()

    addons && addons.forEach(this.addAddon)
  }

  updateContent = (element: HTMLElement | string) => {
    flushElement(this.contentElement)

    this.contentElement.appendChild(typeof element === 'string' ? document.createTextNode(element) : element)
    this.activated && this.updateProgrammatically()
  }

  activate = () => {
    if (this.activated) return

    if (!this.parentContainerElement) {
      throw new Error(`[ScrollableWrapperInstance.constructor] parentContainerElement is ${this.parentContainerElement}`)
    }

    this.scrollRelatedAddons && this.scrollRelatedAddons.forEach(addon => addon.activate && addon.activate(this))
    this.updateRelatedAddons && this.updateRelatedAddons.forEach(addon => addon.activate && addon.activate(this))

    this.updateProgrammatically()
    this.activated = true
  }

  updateManually = () => {
    if (this.isNextScrollUpdateProgrammatically) {
      this.update(false)
      this.isNextScrollUpdateProgrammatically = false
      return
    }

    this.update(true)
  }

  updateProgrammatically = () => {
    this.update(false)
  }

  flush = () => {
    this.scrollRelatedAddons && this.scrollRelatedAddons.forEach(addon => addon.flush())
    this.updateRelatedAddons && this.updateRelatedAddons.forEach(addon => addon.flush())

    this.observer && this.observer.disconnect()

    this.contentElement.removeEventListener('scroll', this.onContentScroll)
    this.thumbElement.removeEventListener('mousedown', this.onThumbTouch)
    this.trackElement.removeEventListener('mousedown', this.onTrackClick)

    document.removeEventListener('mousemove', this.onThumbMove)
    document.removeEventListener('mouseup', this.onThumbUntouch)

    !this.embedded && window.removeEventListener('resize', this.updateProgrammatically)
  }

  //

  getContentHeight = (calculateMargin?: boolean) => {
    const { children } = this.contentElement
    let height = 0
    for (let i = 0; i < children.length; i++) {
      const element = (children[i] as HTMLElement)
      const { offsetHeight } = element

      if (!calculateMargin) {
        height += offsetHeight
        continue
      }

      const styles = window.getComputedStyle(element)
      const margin = styles ? (parseFloat(styles.marginTop || '0') + parseFloat(styles.marginBottom || '0')) : 0
      height += margin ? Math.ceil(offsetHeight + margin) : offsetHeight
    }
    return height
  }

  getDistanceFromBottom = (cached = true) => {
    if (cached) {
      return this.cachedScrollHeight - (this.cachedScrollTop + this.cachedClientHeight)
    }
    const { scrollTop, scrollHeight, clientHeight } = this.contentElement
    return scrollHeight - (scrollTop + clientHeight)
  }

  getDistanceFromTop = (cached = true) => {
    return cached ? this.cachedScrollTop : this.contentElement.scrollTop
  }

  setUpdateCallback = (func: UpdateCallback) => {
    this.updateCallback = func
  }

  scrollTop = () => {
    this.setScrollTop()
  }

  scrollDown = () => {
    this.setScrollTop(this.contentElement.scrollHeight)
  }

  setScrollTop = (value?: number) => {
    this.contentElement.scrollTop = value || 0

    this.isNextScrollUpdateProgrammatically = true
  }

  getContentElement = () => this.contentElement

  private calculateFirefoxScrollMargin () {
    const { offsetWidth, clientWidth } = this.contentElement
    const difference = offsetWidth - clientWidth
    firefoxInnerMargin = Math.max(difference, 0)
    firefoxInnerMargin > 0 && this.fixFirefoxScrollMargin()
  }

  private fixFirefoxScrollMargin (element?: HTMLElement) {
    element = element || this.contentElement
    element.style.width = `calc(100% + ${firefoxInnerMargin}px)`
  }

  private addAddon = (addon: BaseAddon) => {
    const scrollRelated = !!addon.onScroll
    if (scrollRelated) return this.addScrollRelatedAddon(addon)

    const updateRelated = !!addon.onUpdate
    updateRelated && this.addUpdateRelatedAddon(addon)
  }

  private addScrollRelatedAddon = (addon: BaseAddon) => {
    const addons = this.scrollRelatedAddons || (this.scrollRelatedAddons = [])
    addons.push(addon)

    this.activated && addon.activate && addon.activate(this)
  }

  private addUpdateRelatedAddon = (addon: BaseAddon) => {
    const addons = this.updateRelatedAddons || (this.updateRelatedAddons = [])
    addons.push(addon)

    this.activated && addon.activate && addon.activate(this)
  }

  private onContentScroll = () => {
    requestAnimationFrame(this.updateManually)
    this.scrollRelatedAddons && this.scrollRelatedAddons.forEach(addon => (addon as any).onScroll(this))
  }

  private onThumbTouch = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    document.addEventListener('mousemove', this.onThumbMove)
    document.addEventListener('mouseup', this.onThumbUntouch)

    const { target, clientY } = e
    const { offsetHeight } = target as HTMLElement
    const { top } = (target as HTMLElement).getBoundingClientRect()
    this.previousPageY = offsetHeight - (clientY - top)
  }

  private onThumbUntouch = () => {
    this.previousPageY = 0
    document.removeEventListener('mousemove', this.onThumbMove)
    document.removeEventListener('mouseup', this.onThumbUntouch)
  }

  private onThumbMove = (e: MouseEvent) => {
    if (this.previousPageY) {
      const { clientY } = e
      const { top: trackTop } = this.trackElement.getBoundingClientRect()
      const thumbHeight = this.getThumbHeight()
      const clickPosition = thumbHeight - this.previousPageY
      const offset = -trackTop + clientY - clickPosition
      this.contentElement.scrollTop = this.getScrollTopByOffset(offset)
    }
  }

  private onTrackClick = (e: MouseEvent) => {
    e.preventDefault()

    const { target, clientY } = e
    const { top: targetTop } = (target as HTMLElement).getBoundingClientRect()
    const thumbHeight = this.getThumbHeight()
    const offset = Math.abs(targetTop - clientY) - thumbHeight / 2
    this.contentElement.scrollTop = this.getScrollTopByOffset(offset)
  }

  private update = (manually: boolean) => {
    const { scrollTop, scrollHeight, clientHeight } = this.contentElement

    const height = this.trackElement.clientHeight * clientHeight / scrollHeight
    this.thumbElement.style.height = `${height}px`

    const y = this.trackElement.clientHeight * scrollTop / scrollHeight
    this.thumbElement.style.transform = `translateY(${y}px)`

    this.updateCallback && this.updateCallback(scrollTop, scrollHeight, clientHeight, manually)

    this.updateRelatedAddons && this.updateRelatedAddons.forEach(addon => {
      (addon as any).onUpdate(scrollTop, scrollHeight, clientHeight, manually)
    })

    const isVisible = scrollHeight > clientHeight
    if (this.preIsVisible !== isVisible) {
      this.preIsVisible = isVisible
      this.trackElement.style.visibility = isVisible ? 'visible' : 'hidden'

      this.scrollRelatedAddons && this.scrollRelatedAddons.forEach(addon => (addon as any).onScroll(this))
    }

    this.cachedScrollHeight = scrollHeight
    this.cachedScrollTop = scrollTop
    this.cachedClientHeight = clientHeight
  }

  private getThumbHeight = () => {
    const thumbHeight = this.contentElement.clientHeight / this.contentElement.scrollHeight * this.trackElement.clientHeight
    const result = (thumbHeight << 0)
    return result === thumbHeight ? result : result + 1
  }

  private getScrollTopByOffset = (offset: number) => {
    const thumbHeight = this.getThumbHeight()
    return offset / (this.trackElement.clientHeight - thumbHeight) * (this.contentElement.scrollHeight - this.contentElement.clientHeight)
  }

  private attachEvents = () => {
    if (!this.embedded) {
      this.observer = new MutationObserver(this.updateProgrammatically)
      this.observer.observe(this.contentElement, { attributes: true, childList: true, subtree: true })
    }

    this.contentElement.addEventListener('scroll', this.onContentScroll)
    this.thumbElement.addEventListener('mousedown', this.onThumbTouch)
    this.trackElement.addEventListener('mousedown', this.onTrackClick)

    !this.embedded && window.addEventListener('resize', this.updateProgrammatically)
  }

  private createScrollableAreaElement = (): HTMLElement => {
    const thumbElement = DOMUtils.createElement('div', { class: THUMB_CLASS_NAME })
    const trackElement = DOMUtils.createElement('div', { class: TRACK_CLASS_NAME }, thumbElement)

    const contentElement = DOMUtils.createElement('div', {
      class: CONTENT_CLASS_NAME,
    })

    return DOMUtils.createElement('div', {
      class: `${AREA_CLASS_NAME} ${this.appearance}`,
    }, trackElement, contentElement)
  }
}
