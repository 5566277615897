import { uniqBy, sortBy } from 'lodash'
import { date } from 'quasar'
import i18n from '@/i18n'

import { preventHtmlTags, format, formatTextWrappers } from '@/utils'

import { defaultLogger } from '@/loggers'

export const getAspectRatioSize = ({ maxWidth, maxHeight, width, height }: { maxWidth: number; maxHeight: number; width: number; height: number }) => {
  if (width < maxWidth && height < maxHeight) {
    return { width, height }
  }

  const ratio = Math.min(maxWidth / width, maxHeight / height)
  return { width: width * ratio, height: height * ratio }
}

export const formatTime = (sourceDate: Date, full: boolean): string => {
  const now = Date.now()

  const format: Intl.DateTimeFormatOptions = {
    ...(full && !date.isSameDate(sourceDate, now, 'year') ? { year: 'numeric' } : {}),
    ...(full && date.getDateDiff(sourceDate, now, 'days') !== 0 ? { month: 'long', day: 'numeric' } : {}),
    hour: 'numeric',
    minute: 'numeric',
  }
  return new Intl.DateTimeFormat(i18n.locale, format).format(sourceDate).replace(' г.', '')
}

export const formatText = ({ value, links = false, emoji = true, noop = false, preventHTML = true }: { value: string; links?: boolean; emoji?: boolean; noop?: boolean; preventHTML?: boolean }) => {
  value = preventHTML ? preventHtmlTags(value) : value

  value = formatTextWrappers(value, noop)
  value = format(value, noop, links, emoji, false)
  value = value.replace(/\n/g, '<br>')
  return value
}

export const copy = (text: string) => {
  if (!text) return

  if ((window as any).clipboardData && (window as any).clipboardData.setData) {
    (window as any).clipboardData.setData.setData('Text', text)
    return
  }

  if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea')
    textarea.textContent = text
    textarea.style.backgroundColor = textarea.style.color = 'transparent'
    textarea.style.position = 'fixed'

    document.body.appendChild(textarea)
    textarea.select()
    try {
      document.execCommand('copy')
    } catch (ex) {
      defaultLogger.warn('Copy to clipboard failed.', ex)
    } finally {
      document.body.removeChild(textarea)
    }
  }
}

export function bind (fn: (...params: unknown[]) => unknown, context: any): (...args: unknown[]) => unknown {
  return function (...args: any[]) {
    return fn.apply(context, args)
  }
}

/**
 * Find element by not correct keyboard layout and transliterations
 * In fuse.js options includeScore: true
 * @param searchFn the function of finding an element by the search string
 * @param filter search string
 * @param uniqueKey duplicate filtering key
 * @param isNotNeedCleaned fuse off mode
 */
export const multiSearch = (searchFn: any, filter: string, uniqueKey?: string, isNotNeedCleaned?: boolean) => {
  const dictionary: { [letter: string]: string } = { q: 'й', w: 'ц', e: 'у', r: 'к', t: 'е', y: 'н', u: 'г', i: 'ш', o: 'щ', p: 'з', '[': 'х', ']': 'ъ', a: 'ф', s: 'ы', d: 'в', f: 'а', g: 'п', h: 'р', j: 'о', k: 'л', l: 'д', ';': 'ж', '\'': 'э', z: 'я', x: 'ч', c: 'с', v: 'м', b: 'и', n: 'т', m: 'ь', ',': 'б', '.': 'ю', й: 'q', ц: 'w', у: 'e', к: 'r', е: 't', н: 'y', г: 'u', ш: 'i', щ: 'o', з: 'p', х: '[', ъ: ']', ф: 'a', ы: 's', в: 'd', а: 'f', п: 'g', р: 'h', о: 'j', л: 'k', д: 'l', ж: ';', э: '\'', я: 'z', ч: 'x', с: 'c', м: 'v', и: 'b', т: 'n', ь: 'm', б: ',', ю: '.' }
  const word = filter.replace(/[a-zа-я]/gi, m => dictionary[m] || m)

  const dictionary2: { [letter: string]: string } = { а: 'a', б: 'b', в: 'v', г: 'g', д: 'd', е: 'e', ж: 'zh', з: 'z', и: 'i', к: 'k', л: 'l', м: 'm', н: 'n', о: 'o', п: 'p', р: 'r', с: 's', т: 't', у: 'u', ф: 'f', х: 'h', ц: 'c', ч: 'ch', ш: 'sh', щ: 'shch', ы: 'y', э: 'e', ю: 'u', я: 'ya', a: 'а', b: 'б', v: 'в', g: 'г', d: 'д', e: 'е', zh: 'ж', z: 'з', i: 'и', k: 'к', l: 'л', m: 'м', n: 'н', o: 'о', p: 'п', r: 'р', s: 'с', t: 'т', u: 'у', f: 'ф', h: 'х', c: 'ц', ch: 'ч', sh: 'ш', shch: 'щ', y: 'ы', yu: 'ю', ya: 'я', ё: 'yo', yo: 'ё' }
  const word2 = filter.replace(/(ya|yo|yu|ch|shch|sh|zh|[a-zа-яё])/gi, m => dictionary2[m] || m)

  const resultBase = searchFn(filter) // dumb result
  const result = searchFn(word) // result for wrong input source layout (user forgot to switch lang on keyboard)
  const result2 = searchFn(word2) // result for transliterated input

  const combinedResults = [...resultBase, ...result, ...result2]
  const sortedResults = sortBy(combinedResults, 'score')
  // if need we remove data that was pushed by searchFn (Fuse)
  return uniqBy(isNotNeedCleaned ? sortedResults
    : sortedResults.map(result => result.item), result => result[uniqueKey || 'jid'])
}

export const gentimeToDate = (gentime: number): Date | undefined => {
  if (!gentime || gentime < 1000000000000000000) return
  const date = new Date(gentime / 1000000)
  if (isNaN(date.getTime())) return
  return date
}
