import ScrollableInstance, { BaseAddon } from '@/components/UI/ScrollableArea/ScrollableInstance'
import { throttle } from 'quasar'

export default class implements BaseAddon {
  throttledCastShadowIfNeeded: ReturnType<typeof throttle>
  preShowShadow: boolean

  constructor () {
    this.throttledCastShadowIfNeeded = throttle(this.castShadowIfNeeded, 75)
    this.preShowShadow = false
  }

  onScroll = (instance: ScrollableInstance) => {
    this.throttledCastShadowIfNeeded(instance)
  }

  flush = () => {
    //
  }

  private castShadowIfNeeded = (instance: ScrollableInstance) => {
    if (!instance.parentContainerElement) return

    const { scrollTop } = instance.contentElement

    const showShadow = scrollTop > 0

    if (this.preShowShadow === showShadow) return

    showShadow
      ? instance.contentElement.classList.add('shadow')
      : instance.contentElement.classList.remove('shadow')

    this.preShowShadow = showShadow
  }
}
