//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ScrollableInstance from './ScrollableInstance'

import ShadowCastAddon from './ShadowCastAddon'
import BlinkingScrollbarAddon from './BlinkingScrollbarAddon'
import ContentEdgeFadingAddon from './ContentEdgeFadingAddon'

export default {
  name: 'ScrollableArea',
  props: {
    flex: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    appearance: {
      type: String,
      default: 'default',
    },
    castShadow: {
      type: Boolean,
      default: false,
    },
    blinking: {
      type: Boolean,
      default: false,
    },
    edgeFading: {
      type: Boolean,
      default: false,
    },
  },
  updated () {
    this.update()
  },
  mounted () {
    const addons = []
    this.castShadow && addons.push(new ShadowCastAddon())
    this.blinking && addons.push(new BlinkingScrollbarAddon(1000))
    this.edgeFading && addons.push(new ContentEdgeFadingAddon())

    this.instance = new ScrollableInstance({
      container: this.$el,
      appearance: this.appearance,
      addons: addons.length > 0 ? addons : null,
    })

    this.instance.activate()
  },
  beforeDestroy () {
    this.instance.flush()
  },
  methods: {
    getContentElement () {
      return this.instance.getContentElement()
    },
    getHeight () {
      const contentElement = this.instance.getContentElement()
      return contentElement ? contentElement.clientHeight : 0
    },
    update () {
      this.instance.updateProgrammatically()
    },
    getContentHeight (calculateMargin) {
      return this.instance.getContentHeight(calculateMargin)
    },
    getDistanceFromBottom () {
      return this.instance.getDistanceFromBottom()
    },
    getDistanceFromTop () {
      return this.instance.getDistanceFromTop()
    },
    scrollTop () {
      this.instance.scrollTop()
    },
    scrollDown () {
      this.instance.scrollDown()
    },
    scrollTo (offset) {
      this.instance.setScrollTop(offset)
    },
    setUpdateCallback (func) {
      this.instance.setUpdateCallback(func)
    },
  },
}
