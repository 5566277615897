import ScrollableInstance, { BaseAddon } from '@/components/UI/ScrollableArea/ScrollableInstance'
import { throttle } from 'quasar'

const cn = 'edge-fading'
const cnTop = cn + '--top'
const cnBottom = cn + '--bottom'

export default class implements BaseAddon {
  throttled: ReturnType<typeof throttle>

  constructor () {
    this.throttled = throttle(this.check, 75)
  }

  onScroll = (instance: ScrollableInstance) => {
    this.throttled(instance)
  }

  flush = () => {
    //
  }

  private check = (instance: ScrollableInstance) => {
    if (!instance.parentContainerElement) return

    const fromBottom = instance.getDistanceFromBottom(false)
    const fromTop = instance.getDistanceFromTop(false)

    if (fromBottom || fromTop) {
      instance.contentElement.classList.add(cn)

      fromTop
        ? instance.contentElement.classList.add(cnTop)
        : instance.contentElement.classList.remove(cnTop)

      fromBottom
        ? instance.contentElement.classList.add(cnBottom)
        : instance.contentElement.classList.remove(cnBottom)
    } else {
      instance.contentElement.classList.remove(cn, cnTop, cnBottom)
    }
  }
}
